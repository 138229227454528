import "../css/tournament.css";
import Banner from "../assets/warturnir.png";
export default function Tournament() {
  return (
    <>
      <div className="tourney-header main">
        <img src={Banner} alt="Banner" className="w-100 h-auto" />
      </div>
      <div className="flex flex-column align-center main">
        <div className="tourney-info ">
          <h2>Format i pravila igranja</h2>
          <div>
            <ul>
              <li>WAR se igra na maloj farmi</li>
              <li>Igra se u formatu duple eliminacije</li>
              <li>
                Za svako kolo se igra uzvratni WAR, odnosno svaka ekipa igra na
                obje strane
              </li>
              <li>
                Dalje prolazi ekipa koja zbrojeno ima više killova nakon 2
                odigrana WARa
              </li>
              <li>
                Igrači moraju biti spremni najkasnije 5 minuta do početka WARa
              </li>
              <li>
                Za vrijeme WARa, s obzirom da mala farma nije ograđena,
                zabranjeno je bježanje sa mape, svaki WAR će biti praćen od
                strane admina i svatko tko pokuša bježati će biti
                diskvalificiran
              </li>
              <br />
              <h2 className=".tourney-info">Sretno svima!</h2>
            </ul>
          </div>
        </div>
        <iframe
          src="https://challonge.com/go6b0hqy/module"
          width="100%"
          height="1000"
          frameborder="0"
          scrolling="auto"
          allowtransparency="false"
        ></iframe>
      </div>
    </>
  );
}

import { useState, useEffect } from "react";
import {
  getTopDmz,
  getTopEvent,
  getTopGold,
  getTopMoney,
} from "../utils/Fetch";
import "../css/topstats.css";
import Banner from "../assets/top10.png";
import Loading from "../components/Loading";

export default function Topstats() {
  const [topMoney, setTopMoney] = useState([]);
  const [topEvent, setTopEvent] = useState([]);
  const [topGold, setTopGold] = useState([]);
  const [topDmz, setTopDmz] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // Start loading
    const fetchAllData = async () => {
      try {
        const [dmzData, moneyData, eventData, goldData] = await Promise.all([
          getTopDmz(),
          getTopMoney(),
          getTopEvent(),
          getTopGold(),
        ]);

        // Set all states with the fetched data
        setTopDmz(dmzData);
        setTopMoney(moneyData);
        setTopEvent(eventData);
        setTopGold(goldData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // End loading when all data is fetched
      }
    };

    fetchAllData();
  }, []);

  // Conditional rendering
  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div>
      <div className="flex justify-center main">
        <img src={Banner} alt="Banner" className="w-100 h-auto" />
      </div>

      <div className="flex wrap">
        <div className="window font-m">
          <div className="window-header flex space-between">Najbogatijih</div>
          {topMoney.map((player, i) => {
            const playerName = player.p_name.replace(/_/g, " ");
            return (
              <div key={i} className="window-player flex space-between">
                <span>{playerName}</span>
                <span>
                  {Intl.NumberFormat("en-US").format(player.novci) + "$"}
                </span>
              </div>
            );
          })}
        </div>
        <div className="window font-m">
          <div className="window-header flex space-between ">Eventi</div>
          {topEvent.map((player, i) => {
            const playerName = player.p_name.replace(/_/g, " ");
            return (
              <div key={i} className="window-player flex space-between">
                <span>{playerName}</span>
                <div className="flex space-between podiums font-m">
                  <span className="white ">{player.event_first_place} 🥇</span>
                  <span className="white">{player.event_second_place} 🥈</span>
                  <span className="white">{player.event_third_place} 🥉</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="window font-m">
          <div className="window-header flex space-between font-m">
            DMZ Score
          </div>
          {topDmz.map((player, i) => {
            const playerName = player.p_name.replace(/_/g, " ");
            return (
              <div key={i} className="window-player flex space-between ">
                <span>{playerName}</span>
                <span>{player.dm_event_kills} 💀</span>
              </div>
            );
          })}
        </div>
        <div className="window font-m">
          <div className="window-header flex space-between">Zlato</div>
          {topGold.map((player, i) => {
            const playerName = player.p_name.replace(/_/g, " ");
            return (
              <div key={i} className="window-player flex space-between">
                <span>{playerName}</span>
                <span>{player.gold + "g"}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

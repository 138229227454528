import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./views/Main";
import Navbar from "./nav/Navbar";
import "./App.css";
import Organisations from "./views/Organisations";
import Map from "./views/Map";
import Topstats from "./views/Topstats";
import Tournament from "./views/Tournament";

function App() {
  const [navShowing, setNavShowing] = useState(false);
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Navbar navShowing={navShowing} setNavShowing={setNavShowing} />
              <Main />
            </div>
          }
        />
        <Route
          path="/organisations"
          element={
            <div>
              <Navbar navShowing={navShowing} setNavShowing={setNavShowing} />
              <Organisations />
            </div>
          }
        />
        <Route
          path="/map"
          element={
            <div>
              <Navbar navShowing={navShowing} setNavShowing={setNavShowing} />
              <Map navShowing={navShowing} />
            </div>
          }
        />
        <Route
          path="/topstats"
          element={
            <div>
              <Navbar navShowing={navShowing} setNavShowing={setNavShowing} />
              <Topstats />
            </div>
          }
        />
        <Route
          path="/tournament"
          element={
            <div>
              <Navbar navShowing={navShowing} setNavShowing={setNavShowing} />
              <Tournament />
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default App;

import { useState, useEffect } from "react";
import {
  MapContainer,
  ImageOverlay,
  Marker,
  Rectangle,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import gtaMap from "../assets/gta-san-andreas-map.png"; // Adjust the path to your image
import "../css/map.css";
import graffitiIcon from "../assets/graffiti-marker.png";
import bankIcon from "../assets/radar_cash.png";
import packagesIcon from "../assets/packages-marker.png";
import packetVehiclesIcon from "../assets/packet-vehicles.png";
import {
  getGangzoneCoords,
  getGraffitiCoords,
  getBanks,
  getPackages,
  getPacketVehicles,
} from "../utils/Fetch";
import Loading from "../components/Loading";

export default function Map({ navShowing }) {
  const [zoneCoords, setZoneCoords] = useState([]);
  const [graffitiCoords, setGraffitiCoords] = useState([]);
  const [bankCoords, setBankCoords] = useState([]);
  const [packagesCoords, setPackagesCoords] = useState([]);
  const [packetVehiclesCoords, setPacketVehiclesCoords] = useState([]);
  const [showZones, setShowZones] = useState(true);
  const [showGraffiti, setShowGraffiti] = useState(true);
  const [showBanks, setShowBanks] = useState(true);
  const [showPackages, setShowPackages] = useState(true);
  const [showPacketVehicles, setShowPacketVehicles] = useState(true);

  const [loading, setLoading] = useState(true);
  const [showLegend, setShowLegend] = useState(true);

  useEffect(() => {
    // Start loading
    setLoading(true);

    // Fetch all data in parallel using Promise.all
    Promise.all([
      getGangzoneCoords(),
      getGraffitiCoords(),
      getBanks(),
      getPackages(),
      getPacketVehicles(),
    ])
      .then(
        ([
          fetchedZoneCoords,
          fetchedGraffitiCoords,
          fetchedBankCoords,
          fetchedPackagesCoords,
          fetchedPacketVehiclesCoords,
        ]) => {
          // Set each state with the corresponding fetched data
          setZoneCoords(fetchedZoneCoords);
          setGraffitiCoords(fetchedGraffitiCoords);
          setBankCoords(fetchedBankCoords);
          setPackagesCoords(fetchedPackagesCoords);
          setPacketVehiclesCoords(fetchedPacketVehiclesCoords);
        }
      )
      .catch((error) => {})
      .finally(() => {
        // Stop loading after fetching is done (either success or failure)
        setLoading(false);
      });
  }, []);

  // Conditional rendering based on loading state
  if (loading) {
    return <Loading />; // Render loading view while data is being fetched
  }
  const bounds = [
    [-3200, -3210], // Top-left corner (y_max, x_min)
    [3260, 3260], // Bottom-right corner (y_min, x_max)
  ];

  const graffitiMarker = L.icon({
    iconUrl: graffitiIcon,
    iconSize: [10, 15],
    iconAnchor: [7.5, 15],
    popupAnchor: [0, -50],
  });

  const bankMarker = L.icon({
    iconUrl: bankIcon,
    iconSize: [15, 15],
    iconAnchor: [7.5, 15],
    popupAnchor: [0, -50],
  });

  const packagesMarker = L.icon({
    iconUrl: packagesIcon,
    iconSize: [10, 15],
    iconAnchor: [7.5, 15],
    popupAnchor: [0, -50],
  });

  const packetVehiclesMarker = L.icon({
    iconUrl: packetVehiclesIcon,
    iconSize: [10, 15],
    iconAnchor: [7.5, 15],
    popupAnchor: [0, -50],
  });

  return (
    <div>
      <>
        {!navShowing && (
          <div className="legend-manager flex wrap">
            <div className="flex space-between align-center">
              <h4>Legenda</h4>
              <span
                className="close"
                onClick={() => {
                  setShowLegend(!showLegend);
                }}
              >
                ✕
              </span>
            </div>
            {showLegend && (
              <>
                <div className="flex space-between">
                  <p>Zone</p>
                  <input
                    type="checkbox"
                    onClick={() => {
                      setShowZones(!showZones);
                    }}
                    defaultChecked={showZones}
                  />
                </div>
                <div className="flex space-between">
                  <div className="flex align-center">
                    <img src={graffitiIcon} alt="Marker" height="20px" />
                    &nbsp;
                    <p>Graffiti</p>
                  </div>

                  <input
                    type="checkbox"
                    onClick={() => {
                      setShowGraffiti(!showGraffiti);
                    }}
                    defaultChecked={showGraffiti}
                  />
                </div>
                <div className="flex space-between">
                  <div className="flex align-center">
                    <img src={packagesIcon} alt="Marker" height="20px" />
                    &nbsp;
                    <p>Paketi</p>
                  </div>
                  <input
                    type="checkbox"
                    onClick={() => {
                      setShowPackages(!showPackages);
                    }}
                    defaultChecked={showPackages}
                  />
                </div>
                <div className="flex space-between">
                  <div className="flex align-center">
                    <img src={packetVehiclesIcon} alt="Marker" height="20px" />
                    &nbsp;
                    <p>Vozila</p>
                  </div>
                  <input
                    type="checkbox"
                    onClick={() => {
                      setShowPacketVehicles(!showPacketVehicles);
                    }}
                    defaultChecked={showPacketVehicles}
                  />
                </div>
                <div className="flex space-between">
                  <div className="flex align-center">
                    <img src={bankIcon} alt="Marker" height="20px" />
                    &nbsp;
                    <p>Banke i zlatare</p>
                  </div>
                  <input
                    type="checkbox"
                    onClick={() => {
                      setShowBanks(!showBanks);
                    }}
                    defaultChecked={showBanks}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </>
      <MapContainer
        center={[0, 0]} // Set the map's initial center to [0, 0]
        zoom={-3} // Initial zoom level
        minZoom={-4} // Minimum zoom level
        maxZoom={1} // Maximum zoom level
        zoomDelta={0.2} // Step for each zoom in/out (0.5 zoom step)
        zoomSnap={0.2} // Controls zoom snapping to specific levels (set to 0.5)
        crs={L.CRS.Simple}
        style={{ height: "100vh", width: "100%", backgroundColor: "#6b8bb2" }}
      >
        {/* Image overlay for the GTA map */}
        <ImageOverlay url={gtaMap} bounds={bounds} />

        {zoneCoords.map((coord, i) => {
          const tiles = {
            bounds: [
              [coord.max_y, coord.min_x],
              [coord.min_y, coord.max_x],
            ],
            color: "#" + coord.color,
            opacity: "0.6",
          };

          return (
            <div key={i}>
              {showZones && (
                <Rectangle
                  bounds={tiles.bounds}
                  pathOptions={{
                    color: tiles.color,
                    weight: 1,
                    opacity: tiles.opacity,
                    fillOpacity: tiles.opacity,
                  }}
                >
                  <Popup>
                    <b>{coord.name ? coord.name : "Slobodno"}</b>
                  </Popup>
                </Rectangle>
              )}
            </div>
          );
        })}

        {graffitiCoords.map((graffito, i) => {
          return (
            <div key={i}>
              {showGraffiti && (
                <Marker
                  position={[graffito.sp_y, graffito.sp_x]}
                  icon={graffitiMarker}
                >
                  <Popup>
                    <div className="popup">
                      <b>{graffito.name ? graffito.name : "Slobodno"}</b>
                    </div>
                  </Popup>
                </Marker>
              )}
            </div>
          );
        })}

        {bankCoords.map((bank, i) => {
          return (
            <div key={i}>
              {showBanks && (
                <Marker position={[bank.gps_y, bank.gps_x]} icon={bankMarker}>
                  <Popup>
                    <div className="popup">
                      <b>{bank.gps_name ? bank.gps_name : ""}</b>
                    </div>
                  </Popup>
                </Marker>
              )}
            </div>
          );
        })}

        {packagesCoords.map((pack, i) => {
          return (
            <div key={i}>
              {showPackages && (
                <Marker
                  position={[pack.gps_y, pack.gps_x]}
                  icon={packagesMarker}
                >
                  <Popup>
                    <div className="popup">
                      <b>{pack.gps_name ? pack.gps_name : ""}</b>
                    </div>
                  </Popup>
                </Marker>
              )}
            </div>
          );
        })}

        {packetVehiclesCoords.map((vehicle, i) => {
          return (
            <div key={i}>
              {showPacketVehicles && (
                <Marker
                  position={[vehicle.gps_y, vehicle.gps_x]}
                  icon={packetVehiclesMarker}
                >
                  <Popup>
                    <div className="popup">
                      <b>{vehicle.gps_name ? vehicle.gps_name : ""}</b>
                    </div>
                  </Popup>
                </Marker>
              )}
            </div>
          );
        })}
      </MapContainer>
    </div>
  );
}

import { useState, useEffect } from "react";
import { getOrganisations, getGangzones, getGraffiti } from "../utils/Fetch";
import "../css/organisations.css";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Banner from "../assets/organizacije.png";
import Grafiti from "../assets/grafiti.png";
import Teritorije from "../assets/teritorije.png";
import Loading from "../components/Loading";

export default function Organisations() {
  const [organisations, setOrganisations] = useState([]);
  const [gangzones, setGangzones] = useState([]);
  const [graffiti, setGraffiti] = useState([]);
  const [visibleOrganisationId, setVisibleOrganisationId] = useState(null);
  const [loading, setLoading] = useState(true);

  const togglePopup = (orgId) => {
    setVisibleOrganisationId((prevId) => (prevId === orgId ? null : orgId));
  };

  // Chart

  ChartJS.register(ArcElement, Tooltip, Legend);

  const gangzoneChartData = {
    labels: gangzones.map((zone) => (zone.name ? zone.name : "Slobodno")),
    datasets: [
      {
        label: "Teritorije",
        data: gangzones.map((zone) => zone.ukupno),
        backgroundColor: gangzones.map((zone) =>
          zone.name ? "#" + zone.color + "90" : "#ff000090"
        ),
        borderColor: gangzones.map((zone) =>
          zone.name ? "#" + zone.color : "#ff0000"
        ),
        borderWidth: 1,
      },
    ],
  };

  const graffitiChartData = {
    labels: graffiti.map((grafito) =>
      grafito.name ? grafito.name : "Slobodno"
    ),
    datasets: [
      {
        label: "Grafiti",
        data: graffiti.map((grafito) => grafito.ukupno),
        backgroundColor: graffiti.map((grafito) =>
          grafito.name ? "#" + grafito.color + "90" : "#ff000090"
        ),
        borderColor: graffiti.map((grafito) =>
          grafito.name ? "#" + grafito.color : "#ff0000"
        ),
        borderWidth: 1,
      },
    ],
  };

  const chartDataOptions = {
    plugins: {
      legend: {
        display: false,
        position: "right",
        align: "start",
      },
      radius: 2,
    },
  };

  // Fetch Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedOrganisations, fetchedGangzones, fetchedGraffiti] =
          await Promise.all([
            getOrganisations(),
            getGangzones(),
            getGraffiti(),
          ]);

        setOrganisations(fetchedOrganisations);
        setGangzones(fetchedGangzones);
        setGraffiti(fetchedGraffiti);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // End loading once all data is fetched
      }
    };

    fetchData();
  }, []);

  // Conditional rendering
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex justify-center main">
        <img src={Banner} alt="Banner" className="w-100 h-auto" />
      </div>

      <div className="main text-center flex wrap space-around">
        {organisations.map((organisation, i) => {
          let leaders = "";
          if (organisation.lideri) {
            leaders = organisation.lideri.replace(/_/g, " ");
          }

          return (
            <div
              onClick={() => togglePopup(organisation.org_id)}
              key={i}
              className="organisation flex align-center justify-center"
              style={{
                color: "white",
                backgroundColor: organisation.color
                  ? "#" + organisation.color + "50"
                  : "#4d4d4d50",
                borderColor: organisation.color
                  ? "#" + organisation.color
                  : "#4d4d4d",
              }}
            >
              <span>{organisation.name}</span>
              {visibleOrganisationId === organisation.org_id && (
                <div className="popup-org">
                  <span className="popuptext-org show-org">
                    <div className="flex space-around">
                      <img
                        height="175px"
                        src={`https://assets.open.mp/assets/images/skins/${organisation.male_skin_6}.png`}
                        alt="skinID"
                      />
                      <div className="flex flex-column align-center justify-center">
                        <p>
                          <b>{organisation.prefix}</b>
                        </p>
                        {organisation.savez_name && (
                          <p className="font-s">
                            Savez: {organisation.savez_name}
                          </p>
                        )}
                        {organisation.lideri && (
                          <p className="font-s">
                            <span>Lideri: </span>
                            <br /> {leaders}
                          </p>
                        )}
                      </div>
                    </div>
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div>
        <div className="flex justify-center main">
          <img src={Teritorije} alt="Banner" className="w-100 h-auto" />
        </div>
        <div className="main text-center chart">
          <Pie data={gangzoneChartData} options={chartDataOptions} />
        </div>
        <div className="flex justify-center main">
          <img src={Grafiti} alt="Banner" className="w-100 h-auto" />
        </div>
        <div className="main text-center chart">
          <Pie data={graffitiChartData} options={chartDataOptions} />
        </div>
        <div className="empty-space"></div>
      </div>
    </div>
  );
}

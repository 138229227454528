import "../css/nav.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Navbar({ navShowing, setNavShowing }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const routes = [
    { route: "/", text: "INFO" },
    { route: "/organisations", text: "ORGANIZACIJE" },
    { route: "/topstats", text: "TOP 10" },
    { route: "/map", text: "KARTA" },
    { route: "/tournament", text: "TURNIR" },
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle menu open/close
  };

  return (
    <>
      <div className="nav-container">
        <div
          className="hamburger-icon"
          onClick={() => {
            toggleMenu();
            setNavShowing(!navShowing);
          }}
        >
          <div className={`bar ${menuOpen ? "open" : ""}`}></div>
          <div className={`bar ${menuOpen ? "open" : ""}`}></div>
          <div className={`bar ${menuOpen ? "open" : ""}`}></div>
        </div>
        <div
          className={`nav text-center flex justify-center ${
            menuOpen ? "menu-open" : ""
          }`}
        >
          {routes.map((route, i) => (
            <div key={i} className="link-wrapper">
              <NavLink
                onClick={() => setMenuOpen(false)} // Optional: Close menu on link click
                className={({ isActive }) => (isActive ? "active-link" : "")}
                to={route.route}
              >
                {route.text}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
      <div className="line"></div>
    </>
  );
}

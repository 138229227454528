import { getPlayers, getInfo } from "../utils/Fetch";
import { useState, useEffect } from "react";
import "../css/main.css";
import Player from "../components/Player";
import Banner from "../assets/croherze.png";
import Loading from "../components/Loading";

export default function Main() {
  const [players, setPlayers] = useState([]);
  const [info, setInfo] = useState({});
  const [visiblePlayerId, setVisiblePlayerId] = useState(null);
  const [loading, setLoading] = useState(true);

  const togglePopup = (playerId) => {
    // If clicking on the same player, hide the popup. Otherwise, show the new one.
    setVisiblePlayerId((prevId) => (prevId === playerId ? null : playerId));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading

      try {
        // Fetch both info and players in parallel
        const [fetchedInfo, fetchedPlayers] = await Promise.all([
          getInfo(),
          getPlayers(),
        ]);

        // Set the state with the fetched data
        setInfo(fetchedInfo);
        setPlayers(fetchedPlayers);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // End loading once both requests are complete
      }
    };

    fetchData();
  }, []);

  // Conditional rendering: show loading spinner if data is still loading
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex justify-center main">
        <img src={Banner} alt="Banner" className="w-100 h-auto" />
      </div>
      <div className="main white info wrap">
        <div className="server-info">
          <p>
            <b>{info.servername + " | " + info.gamemode}</b>
          </p>
        </div>
        <br />
        <br />
        <div>
          <div className="server-info flex space-between">
            <span>
              <b>{info.players + " / " + info.maxplayers}</b>
            </span>
            <span>
              <b>rp.croherze.com:7777</b>
            </span>
          </div>
        </div>
      </div>
      <div className="flex wrap main info-players">
        {players.map((player, i) => {
          return (
            <Player
              key={i}
              player={player}
              isPopupVisible={visiblePlayerId === player.user_id}
              togglePopup={() => togglePopup(player.user_id)}
            />
          );
        })}
      </div>
      <div className="copyright">CroHerze UCP 1.0.0 by Renco</div>
    </div>
  );
}

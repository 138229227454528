import axiosInstance from "../context/interceptors";

export const getInfo = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/info"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching server info:", error);
    throw error;
  }
};

export const getPlayers = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/players"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching players:", error);
    throw error;
  }
};

export const getOrganisations = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/organisations"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching organisations:", error);
    throw error;
  }
};

export const getGangzones = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/gangzones"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getGangzoneCoords = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/gangzonecoords"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getGraffiti = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/graffiti"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getGraffitiCoords = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/graffiticoords"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getTopMoney = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/topmoney"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getTopEvent = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/topevent"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getTopDmz = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/topdmz"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getTopGold = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/topgold"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getBanks = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/banks"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getPackages = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/packages"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

export const getPacketVehicles = async () => {
  try {
    const response = await axiosInstance.get(
      window._env_.REACT_APP_SERVER + "/packet-vehicles"
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

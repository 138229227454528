import React, { useState } from "react";

export default function Player({ player, isPopupVisible, togglePopup }) {
  let color = "white"; // Default color
  if (player.admin_lvl > 0) {
    color = "yellow";
  } else if (player.helper_lvl > 0) {
    color = "green";
  } else if (player.promoter > 0) {
    color = "pink";
  }

  // Replace underscores with spaces in the player's name
  const playerName = player.p_name.replace(/_/g, " ");

  return (
    <div className="player popup" style={{ color }} onClick={togglePopup}>
      <span>{playerName}</span>
      <span className={`popuptext ${isPopupVisible ? "show" : ""}`}>
        <div className="flex">
          <img
            height="175px"
            src={`https://assets.open.mp/assets/images/skins/${player.skin_id}.png`}
          />
          <div className="flex flex-column justify-center">
            <p style={{ color }}>{playerName}</p>
            <p>Level: {player.level}</p>
            <p>Godine: {player.age}</p>
            <p>Mobitel: {player.phone_number}</p>
          </div>
        </div>
      </span>
    </div>
  );
}
